import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  Routes,
  RouterModule,
} from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LayoutModule } from './layout/layout.module';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
const routerConfig: ExtraOptions = {
  // !!! Don't enable this !!!!
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
  // Landing routes
  {
    path: '',
    // canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'website',
        loadChildren: () =>
          import('./modules/website/website.module').then(
            (m) => m.WebsiteModule
          ),
      },
      {
        path: 'bonds',
        loadChildren: () =>
          import('./modules/bonds/bonds.module').then((m) => m.BondsModule),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./modules/events/events.module').then((m) => m.EventsModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'quotes',
        loadChildren: () =>
          import('./modules/quotes/quotes.module').then((m) => m.QuotesModule),
      },
      {
        path: 'policy',
        loadChildren: () =>
          import('./modules/policy/policy.module').then((m) => m.PolicyModule),
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./modules/tasks/tasks.module').then((m) => m.TasksModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'landingpages',
        loadChildren: () =>
          import('./modules/landing-pages/landing-pages.module').then((m) => m.LandingPagesModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
        import('./modules/accounts/accounts.module').then((m) => m.AccountsModule)
      }
    ],
  },
  {
    path: '404-not-found',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/error/error-404/error-404.module').then(
        (m) => m.Error404Module
      ),
  },
  // { path: '**', redirectTo: '404-not-found' },
  {
    path: '**', loadChildren: () =>
      import('./modules/error/error-404/error-404.module').then(
        (m) => m.Error404Module
      ),
  },
];

// configures NgModule imports and exports
@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
