import { Component } from '@angular/core';

@Component({
  selector: 'sbd-doug-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Doug';
  constructor() {
    //
  }
}
