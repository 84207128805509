import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from '@sbd-doug-frontend/app/core/navigation/navigation.types';
import { NavigationResponse } from '@sbd-doug-frontend/app/models/navigationresponse.model';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from '@sbd-doug-frontend/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<NavigationResponse> {
    return this._httpClient.get<NavigationResponse>(`${environment.apiBaseUrl}/api/v4/configuration/navigation`, { withCredentials: true }).pipe(
      tap((navigation) => {
        this._navigation.next(this.navigationResponseToFuseNavigation(navigation));
      })
    );
  }

  navigationResponseToFuseNavigation(navigationResponse: NavigationResponse): Navigation {
    // TODO: Fill these to allow for theme switching.
    const nav = <Navigation>{
      default: [],
      compact: [],
      futuristic: [],
      horizontal: []
    };

    if (navigationResponse.data.navigation.length === 0) {
      return nav;
    }
    navigationResponse.data.navigation.map(navItem => {
      const fuseItem = <FuseNavigationItem>{
        id: navItem.display_name.replace(' ', '-').toLowerCase(),
        title: navItem.display_name,
        type: 'group',
        children: [],

      };
      if (navItem.icon) {
        fuseItem.icon = navItem.icon;
      }
      if (navItem.href) {
        fuseItem.link = `${environment.dougV2BaseUrl}${navItem.href}`;
        fuseItem.type = 'basic';
        fuseItem.externalLink = true;
      }
      if (navItem.router_link) {
        fuseItem.link = navItem.router_link;
        fuseItem.externalLink = false;
      }
      if (navItem.children && navItem.children.length > 0) {
        fuseItem.children.push(...this.buildChildItems(navItem.children));
      }

      nav.default.push(fuseItem);
      nav.horizontal.push(fuseItem);
      nav.futuristic.push(fuseItem);
      nav.compact.push(fuseItem);
    });
    return nav;
  }

  buildChildItems(items):Array<FuseNavigationItem> {
    const fuseItems:Array<FuseNavigationItem> = [];

    items.map(child => {
      const fuseItemChild = <FuseNavigationItem>{
        id: child.display_name?.replace(' ', '-').toLowerCase(),
        title: child.display_name
      };
      if (child.icon) {
        fuseItemChild.icon = child.icon;
      }
      if (child.divider && child.divider == true) {
        fuseItemChild.type = 'divider';
      } else {
        fuseItemChild.type = 'basic';
        if (child.href) {
          fuseItemChild.link = `${environment.dougV2BaseUrl}${child.href}`;
          fuseItemChild.externalLink = true;
        } else {
          fuseItemChild.link = child.router_link;
        }
      }
      if (child.children && child.children.length > 0) {
        fuseItemChild.type = 'collapsable';
        fuseItemChild.children = [];
        fuseItemChild.children.push(...this.buildChildItems(child.children));
      }
      fuseItems.push(fuseItemChild)
    });

    return fuseItems;
  }
}
