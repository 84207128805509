import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor<T> implements HttpInterceptor {

  intercept(req: HttpRequest<T>, next: HttpHandler):
    Observable<HttpEvent<T>> {
    req = req.clone({
      withCredentials: true
    });
    return next.handle(req);
  }
}
